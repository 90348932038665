$page-width: 100%;
$color-white: #fff;
$color-black: #000;
$margin-1: 1rem;
$page-margin-left: 0.5rem;
$page-margin-right: 0.5rem;



.hamburger {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.hamburger__line {
    position: absolute;
    width: 26px;
    height: 1.5px;
    border: 1.5px solid #000;
    border-radius: 100%;
    background-color: #000;
    transition: 0.3s;
}

.hamburger__line:nth-of-type(1) {
    top: 10px;
}

.hamburger__line:nth-of-type(2) {
    top: 20px;
}

.hamburger__line:nth-of-type(3) {
    top: 30px;
}

.hamburger.active .hamburger__line:nth-of-type(1) {
    transform: rotate(45deg);
    top: 18px;
}

.hamburger.active .hamburger__line:nth-of-type(2) {
    opacity: 0;
}

.hamburger.active .hamburger__line:nth-of-type(3) {
    transform: rotate(-45deg);
    top: 18px;
}

.reseller-header * {
    overflow-y: hidden;
    overflow: hidden;
}

.reseller-header {
    width: $page-width;
    padding-left: $page-margin-left;
    padding-right: $page-margin-right;
    z-index: 1;

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: $margin-1;
    }

    @media screen and (max-width: 400px) {
        $margin-1: 0.5rem;
        &__container {
            gap: 0.5rem
        }
    }

    @media screen and (max-width: 350px) {
        $margin-1: 0.5rem;
        &__container {
            gap: 0
        }
    }

    &__brand {
        margin-right: auto;
        justify-self: auto;
    }

    &__cta {
        
    }

    &__menu-container {

    }

    & ul {
        z-index: 1;
        padding-left: 0;
        padding-right: 0;
        width: 100vw;
        background-color: white;
        list-style: none;
        text-align: center;
        position: absolute;
    }
  
}