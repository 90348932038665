// signle testimonial styling
// classic style
.single-testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;

    .img-company-div {
        height: 70px;
        display: flex;
        align-items: center;
    }

    .img {
        &-icon {
            width: 104px;
            margin: 22px 0;
        }
        &-company {
            width: 140px;
            vertical-align: middle;
        }
    }

    .name {
        margin: 26px 0;
        text-align: center;

        &__bold {
            font-weight: 700;
        }
    }

    .quote {
        font-style: italic;
        line-height: 138%;
        text-align: center;
        max-width: 550px;
    }
}

// medical style
.single-testimonial {
    .img-medical {
        @include max-lg() {
            margin: 6px 0 20px!important;
        }
    }

    .name-medical {
        margin: 0 0 32px;
        text-align: center;
        line-height: 28px;
        font-size: 20px;

        @include max-lg() {
            max-width: 318px;
            margin: 0 0 26px;
        }

        &__bold {
            font-weight: 700;
        }
    }

    .quote-medical {
        font-style: italic;
        line-height: 138%;
        text-align: center;
        max-width: 550px;
        margin-bottom: 28px;

        @include max-lg() {
            margin-bottom: 0;
        }
    }
}

// education style
.testimonials-education {
    .img-education {
        @include max-lg() {
            margin: 6px 0 20px!important;
        }
    }

    .name-education {
        margin: 0 0 32px;
        text-align: center;
        line-height: 28px;
        font-size: 18px;

        @include max-lg() {
            max-width: 318px;
            margin: 0 0 26px;
        }

        &__bold {
            font-weight: 700;
        }
    }

    .quote-education {
        font-style: italic;
        line-height: 138%;
        font-size: 18px;
        text-align: center;
        max-width: 600px;
        margin-bottom: 20px;

        @include max-lg() {
            margin: 0;
        }
    }

    .rec-item-wrapper {
        height: 100%!important;
    }

    .rec-carousel-item-2 {
        .name-education {
            @include max-lg() {
                margin: 0;
            }
        }

        .quote-education {
            @include max-lg() {
                margin: auto!important;
            }
        }
    }
}

// beam version styling
.testimonials-beam-version {
    background: $beam_blue_gradient!important;
    color: white;

    h2 {
        font-weight: 500;
    }

    .single-testimonial {
        .name {
            margin: 6px 0 32px;
            font-size: 20px;
            line-height: 30px;
            font-weight: 300;

            &__bold {
                font-weight: 500;
            }
        }
        
        .quote {
            font-weight: 300;
            margin-bottom: 22px;
            max-width: 680px;
        }
    }

    // carousel
    .rec-slider-container {
        width: 800px!important;

        @include max-lg () {
            width: 350px!important;
        }

        @media (max-width: 400px) {
            width: 300px!important;
        }

        @media (max-width: 350px) {
            width: 280px!important;
        }

        @media (max-width: 320px) {
            width: 250px!important;
        }
    }
}

// obie version styling 
.testimonials-obie-version {
    // carousel
    .rec-slider-container {
        width: 650px!important;

        @include max-lg () {
            width: 350px!important;
        }

        @media (max-width: 400px) {
            width: 300px!important;
        }

        @media (max-width: 350px) {
            width: 280px!important;
        }

        @media (max-width: 320px) {
            width: 250px!important;
        }
    }
}


// all testimonials section styling 
.testimonials {
    background: $background_yellow;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        text-align: center;
        margin-bottom: 30px;
    }

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        cursor: pointer;
    }
}