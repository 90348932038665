// width container so you can see whats inside
// .rec-slider-container {
//     min-width: 350px!important;
// }

// hide arrows
.rec-arrow {
    display: none;
}

// dots button styling
.kdCslO {
    margin-top: 38px!important;
}

// .jhmYzC, .krmNah {
//     width: 14px!important;
//     height: 14px!important;
// }

.pink {
    .sc-gKXOVf, .krmNah, .jYxeHE {
        box-shadow: 0 0 1px 2px $obie_pink!important;
    }

    .krmNah, .jYxeHE, .krmNah:focus, .jYxeHE:focus {
        background-color: $obie_pink!important;
    }

    button:hover {
        color: $obie_pink!important;
        background-color: $obie_pink!important;
    }

    button {
        background: transparent!important;
    }
}

.black {
    .sc-gKXOVf, .krmNah, .jYxeHE {
        box-shadow: 0 0 1px 2px $text_color!important;
    }

    .krmNah, .jYxeHE, .krmNah:focus, .jYxeHE:focus {
        background-color: $text_color!important;
    }

    button:hover {
        color: $text_color!important;
        background-color: $text_color!important;
    }

    button {
        background: transparent!important;
    }
}

.white {
    .sc-gKXOVf, .krmNah, .jYxeHE {
        box-shadow: 0 0 1px 2px $text_white!important;
    }

    .krmNah, .jYxeHE, .krmNah:focus, .jYxeHE:focus {
        background-color: $text_white!important;
    }

    button:hover {
        color: $text_color!important;
        background-color: $text_white!important;
    }

    button {
        background: transparent!important;
    }
}