.meet-beam-version {
    width: 100%;
    background: $beam_blue_gradient;
    color: $text_white;
    position: relative;
    overflow: hidden;

    @include min-lg() {
        height: 717px;
    }

    &__container {
        display: flex;
        flex-direction: row;
        padding: 39px 0 50px;

        @include max-lg() {
            flex-direction: column;
            padding: 34px 0 0;
        }

        .info {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 58%;

            @include max-xl() {
                width: 64%;
            }

            @include max-ml() {
                width: 73%;
            }

            @include max-lg() {
                width: 100%;
                align-items: center;
            }

            h2 {
                font-size: 28px;
                font-weight: 500;
                line-height: 35px;
                margin-bottom: 46px;

                @include max-lg() {
                    font-size: 22px;
                    line-height: 28px;
                    margin-bottom: 30px;
                    text-align: center;
                }
            }

            &__icons {
                display: flex;
                justify-content: space-between;
                height: 473px;

                @include max-lg() {
                    flex-direction: column;
                    height: 100%;
                    max-width: 317px;
                }

                // different widths for columns
                .left-column {
                    width: 49%;

                    @include max-lg() {
                        width: 100%;
                    }
                }

                .right-column {
                    width: 45%;

                    @include max-lg() {
                        width: 100%;
                    }
                }

                &__column {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    // single icon styling
                    .single-icon {
                        display: flex;
                        flex-direction: row;  
                        max-height: 225px;
    
                        @include max-lg() {
                            margin-bottom: 38px;
                            max-height: none;
                        }
    
                        img {
                            width: 38px;
                            height: 38px;
                            margin-right: 12px;
                        }
    
                        &__text {
                            font-size: 17px;
                            font-weight: 300;
                            line-height: 125%;
                            
                            .header {
                                font-size: 20px;
                                font-weight: 400;
                                margin-bottom: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    &__img {
        // desktop img styling
        @include min-lg() {
            position: absolute;
            top: 0;
            left: 55%;
            height: 100%;

            img {
                height: 100%;
            }
        }

        @include max-xl() {
            left: 60%;         
        }

        @include max-ml() {
            left: 63%;
        }

        // mobile img styling
        @include max-lg() {
            width: 73%;
            margin: -10px auto;
            margin-right: -2%;
            min-width: 600px;

            img {
                width: 100%;
            }
        }
    }
}

// *****
// different versions styling
.meet-beam-version-short {
    // section height is biggest change
    height: 638px;

    @include max-lg() {
        height: 100%;
    }

    // width adjusments bc new height
    .info {
        width: 63%;

        @include max-xl() {
            width: 65%;
        }

        @include max-ml() {
            width: 68%;
        }

        @include max-lg() {
            width: 100%;
        }

        .info__icons {
            height: 300px;

            @include max-lg() {
                height: 100%;
            }

            .left-column {
                width: 46%;
                margin-bottom: 20px;

                @include max-ml() {
                    width: 50%;
                }

                @include max-lg() {
                    width: 100%;
                }

                @include max-lg() {
                    margin-bottom: 0;
                }
            }

            .right-column {
                width: 46%;

                @include max-ml() {
                    width: 50%;
                }

                @include max-lg() {
                    width: 100%;
                }
            }
        }
    }

    .meet-beam-version__img {
        @include min-lg() {
            left: 58%;
        }
    }
}

.meet-beam-version-short-more {
    // section height is biggest change
    height: 546px;

    @include max-lg() {
        height: 100%;
    }

    // width adjusments bc new height
    .info {
        width: 63%;

        @include max-xl() {
            width: 65%;
        }

        @include max-ml() {
            width: 68%;
        }

        @include max-lg() {
            width: 100%;
        }

        .info__icons {
            height: 298px;

            @include max-lg() {
                height: 100%;
            }

            .left-column {
                width: 46%;
                margin-bottom: 20px;

                @include max-ml() {
                    width: 50%;
                }

                @include max-lg() {
                    width: 100%;
                }

                @include max-lg() {
                    margin-bottom: 0;
                }
            }

            .right-column {
                width: 46%;

                @include max-ml() {
                    width: 50%;
                }

                @include max-lg() {
                    width: 100%;
                }
            }
        }
    }

    .meet-beam-version__img {
        @include min-lg() {
            left: 58%;
        }
    }
}

.meet-beam-version-short-more-qsr {
    // section height is biggest change
    height: 526px;

    @include max-lg() {
        height: 100%;
    }

    // width adjusments bc new height
    .info {
        width: 63%;

        @include max-xl() {
            width: 65%;
        }

        @include max-ml() {
            width: 68%;
        }

        @include max-lg() {
            width: 100%;
        }

        .info__icons {
            height: 268px;

            @include max-lg() {
                height: 100%;
            }

            .left-column {
                width: 46%;

                @include max-ml() {
                    width: 50%;
                }

                @include max-lg() {
                    width: 100%;
                }

                @include max-lg() {
                    margin-bottom: 0;
                }
            }

            .right-column {
                width: 46%;

                @include max-ml() {
                    width: 50%;
                }

                @include max-lg() {
                    width: 100%;
                }
            }
        }
    }

    .meet-beam-version__img {
        @include min-lg() {
            left: 58%;
        }
    }
}