@import './variables.scss';
@import './mixin.scss';

.reseller-theme {
  // **************
  // global styles
  * {
    font-family: 'Brown Pro', 'Open Sans', sans-serif, Roboto; // <-- please change the font to the reseller font
  }

  html, body {
    background-color: $text_white;
  }

  // **************
  // text and buttons
  button {
    background: $reseller_orange;

    &:hover {
      background: $reseller_green
    }
  }

  a {
    color: $text_black;
    text-decoration: none;

    &:hover {
      color: $reseller_orange;
    }
  }

  // more styling
  .orange, .pink {
    color: $reseller_orange;
  }
}

.obie-theme {
    // **************
    // global styles
    * {
      font-family: 'Brown Pro', 'Open Sans', sans-serif, Roboto;
    }

    html, body {
      background-color: $background_gray_obie;
    }

    // **************
    // text and buttons
    button {
      background: $obie_pink;
      font-size: 22px;

      &:hover {
        background: $obie_light_pink;
        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.15);
      }
    }

    a {
      color: $obie_pink;
      text-decoration: none;
    }

    // more styling
    .pink {
        color: $obie_pink;
    }
}

.beam-theme {
    // **************
    // global styles
    * {
        font-family: 'Gotham', 'Open Sans', sans-serif, Roboto;
    }

    html, body {
        background-color: $background_gray_beam;
    }

    // **************
    // text and buttons
    button {
        background: $beam_pink;
        font-size: 18px;
        font-weight: 400;

        &:hover {
            background: $beam_gradient_hover;
        }
    }

    a {
        color: $beam_pink;
        text-decoration: none;
    }

    // **************
    // global classes with multiple uses 
    .main-width {
    
        @include min-xl() {
          min-width: 1125px!important;
        }
    }

    // more styling
    .pink {
        color: $beam_pink;
    }
}