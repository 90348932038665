// shared styling for both versions
.footer {
    margin-bottom: -22px;

    &__container {
        display: flex;
        flex-direction: column;
        padding: 46px 0;
        align-items: center;
    }

    &__logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;

        img {
            width: 80px;
            margin-bottom: 6px;
        }
    }

    hr {
        width: 90%;
        max-width: 1175px;
        margin: 30px 0;

        @include max-lg() {
            width: 90%;
            max-width: 470px;
        }
    }

    &__copyrights {
        color: $text_dark_gray;
        line-height: 18px;
        width: 100%;
        padding: 0;
        text-align: center;

        @include max-xs() {
            padding: 0 14px;
        }
    }
}

// obie version
.footer-Obie, .footer-Seniors {
    background: $background_gray_obie;

    hr { border: 1px solid #DDDDDD; }

    .footer__copyrights {
        font-size: 14px;
    }
}

// beam version
.footer-BEAM {
    background: $background_black_beam;

    hr { border: 1px solid #C9C9C9; }

    .footer__copyrights {
        font-size: 16px;
    }
}