.reseller-no-page {
    background-color: $text_white;
    text-align: center;
    height: 64vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    button {
        width: 200px;
    }
}