.meet-obie {
    background: $background_yellow;
    padding: 50px 0!important;

    @include max-lg() {
        padding: 34px 0 50px!important;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        // carousel
        .rec-slider-container {
            width: 340px!important;

            @include max-xs () {
                width: 300px!important;
            }

            @include max-xxs () {
                width: 250px!important;
            }
        }

        h2 {
            text-transform: capitalize;
        }

        &__main-info {
            display: flex;
            flex-direction: column;
            margin-top: 42px;
            width: 100%;
            justify-content: center;

            @include min-md() {
                flex-direction: row;
            }

            @include max-lg() {
                margin-top: 0px;
            }

            .desktop-info-icons {
                height: 410px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include max-ml() {
                    height: 510px;
                }
            }

            &__container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 30px;

                @include max-lg() {
                    margin: 0 auto 40px;
                }

                .obie-img {
                    min-width: 475px;
                    margin: 35px auto 0;

                    @include max-xl() {
                        max-width: 475px;
                        min-width: 345px;
                        width: 100%;
                    }

                    @include max-lg() {
                        width: 340px;
                        max-width: none;
                        min-width: auto;
                    }

                    @include max-md () {
                        width: auto;
                    }

                    img {
                        width: 100%;
                        margin: auto;
                    }
                }

                .positions-img {
                    width: 305px;
                    margin: 0 auto;

                    @include max-lg() {
                        width: 251px;
                    }

                    @include max-xs () {
                        max-width: 251px;
                        width: 100%;
                    }
                }

                div {
                    margin: 6px auto 25px;
                    max-width: 440px;

                    @include max-lg() {
                        margin: 0px auto 22px;
                    }
                }
            }
        }

        &__mobile-info {
            cursor: pointer;
        }
    }
}

.icons-info {
    display: flex;
    width: 290px;
    text-align: left;

    @include max-ml() {
        width: 230px;
    }

    @include max-lg() {
        width: 350px;
    }

    img {
        width: 38px;
        height: 38px;
        margin-right: 12px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        color: $text_black;

        .bold {
            margin-bottom: 6px;
        }
    }
}

.meet-obie-classic-new-design {
    .desktop-info-icons {
        height: 330px;

        @include max-ml() {
            height: 410px;
        }
    }
}

// medical style
.meet-obie-medical {
    @media (max-width: $screen-ml) and (min-width: $screen-lg) {
        max-width: 95%!important;
    }

    .desktop-info-icons {
        height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    
        @include max-ml() {
            height: 360px;
        }
    
        .icons-info {
            width: 288px;
    
            @include max-ml() {
                width: 100%;
                // max-width: 466px;
            }
        
            @include max-lg() {
                width: 350px;
            }
        }

        .bottom-icon {
            @media (max-width: $screen-ml) and (min-width: $screen-lg) {
                margin-top: 36px;
            }
        }

        .left-bottom-icon {
            margin-bottom: 20px;

            @media (max-width: 1025px) {
                margin-bottom: 0;
            }
        }
    }
}

// education style
.meet-obie-education {
    .desktop-info-icons {
        height: 370px!important;
    
        .icons-info {
            width: 277px!important;

            @media (max-width: 1000px) and (min-width: $screen-lg) {
                width: 260px!important;
            }
        }
    
        .bold {
            font-size: 22px;
            line-height: 125%;
        }
    }

    .education-info-icons-first {
        height: 350px!important;
    }

    .meet-obie__container__main-info__container {
        .obie-img {
            margin: 30px auto 12px!important;

            @include max-lg() {
                margin: 30px auto 0!important;
            }
        }

        @media (max-width: 1000px) and (min-width: $screen-lg) {
            margin: 0 12px;
        }
    }

    .meet-obie {
        &__container {
            &__mobile-info {
                .bold {
                    font-size: 22px;
                    margin-top: 2px;
                    margin-bottom: 10px!important;
                }
            }
        }
    }
}

// fec style
.meet-obie-fec {
    .desktop-info-icons {
        height: 330px!important;
    
        .icons-info {
            width: 290px!important;

            @media (max-width: 1000px) and (min-width: $screen-lg) {
                width: 260px!important;
            }
        }
    
        .bold {
            font-size: 22px;
            line-height: 125%;
        }
    }

    .meet-obie__container__main-info__container {
        .obie-img {
            margin: 30px auto 12px!important;

            @include max-lg() {
                margin: 30px auto 0!important;
            }
        }

        @media (max-width: 1000px) and (min-width: $screen-lg) {
            margin: 0 12px;
        }
    }

    .meet-obie {
        &__container {
            &__mobile-info {
                .bold {
                    font-size: 22px;
                    margin-top: 2px;
                    margin-bottom: 10px!important;
                }
            }
        }
    }

    .education-info-icons-first {
        height: 330px!important;
    }
}

// new classic version 2
.meet-obie-classic-v2 {
    .desktop-info-icons {
        height: 300px;

        @include max-ml() {
            height: 410px;
        }
    }

    .meet-obie__container__main-info {
        @include max-lg() {
            flex-direction: column;
        }
    }

    .obie-img {
        margin: -70px auto 0;

        @include max-lg() {
            margin: -30px auto 0;
        }

        img {
            width: 105%;
        }
    }
    
    .desktop-info-icons {
        @include max-lg() {
            height: 100%;

            .icons-info {
                margin-bottom: 30px;
                width: 100%;
            }
        }

        .icons-info__text {
            font-size: 17px;
            line-height: 21px;

            .bold {
                font-size: 21px;
            }

            @include max-lg() {
                font-size: 16px;

                .bold {
                    font-size: 20px;
                }
            }
        }
    }

    button {
        margin-top: 42px;
        width: 323px;

        @include max-xl() {
            margin-top: 6px;
        }

        @include max-lg() {
            margin: 6px 0 16px;
        }

        @include max-xs() {
            width: 100%;
        }
    }
}