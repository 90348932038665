.thank-you-popup {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    top: -200%;
}

.thank-you-popup-show {
    -webkit-transform: translateZ(0);
    position: fixed;
    position: -webkit-fixed;
    position: -moz-fixed;
    position: -ms-fixed;
    position: -o-fixed;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
    visibility: visible;
    z-index: 200;
    opacity: 1;
    transform: scale(1);
    transition: all 0.2s ease-in-out; 

    &__overlay {
        width: 100%;
        height: 100%;
	    transition: all 0.3s ease-out;
        background: $text_color;
        opacity: 0.6;
    }

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 16px;
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translateX(-50%);
        background: $text_white;
        border: 1px solid $obie_pink;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.14);
        border-radius: 10px;
        opacity: 1;
        width: 509px;

        @include max-lg() {
            width: 340px;
            height: 326px;
        }

        @media (max-width: 390px) {
            width: 290px;
            height: 350px;
        }

        @include max-xxs() {
            width: 250px;
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 40px 52px;

            @include max-lg() {
                padding: 40px 21px;
            }

            .popup-exit {
                position: absolute;
                top: 21px;
                right: 21px;
                cursor: pointer;

                @include max-xxs() {
                    right: 41px;
                }
            }

            .popup-text {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                h3 {
                    color: $text_color;
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 120%;
                    margin: 24px 0 16px;

                    @include max-lg() {
                        font-size: 22px;
                    }
                }

                span {
                    color: $text_gray_color;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 140%;
                }

                button {
                    padding: 8px 20px;
                    gap: 10px;
                    width: 246px;
                    height: 48px;
                    margin-top: 24px;

                    @include max-lg() {
                        width: 254px;
                    }

                    @include max-xxs() {
                        width: 220px;
                    }

                    span {
                        color: $text_white;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 32px;
                    }
                }
            }
        }
    }
}

@keyframes pop-swirl {
    0% {
      transform: scale(0) rotate(360deg);
    }
    60% {
      transform: scale(0.8) rotate(-10deg);
    }
    100% {
      transform: scale(1) rotate(0deg);
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}