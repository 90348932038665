.thank-you-page {
    background-color: $text_white;
    text-align: center;
    height: 64vh;

    &__container {
        height: 100%;
        width: 100%;
        bottom: 0;
        right: 0;

        .thank-you {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: $text_white;
            width: 100%;
            height: 100%;

            &__content {
                position: relative;
                display: flex;
                flex-direction: column;
                padding: 40px;
                width: 100%;

                @include max-xxs() {
                    padding: 40px 18px;
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    h3 {
                        color: $text_color;
                        font-weight: 700;
                        font-size: 25px;
                        line-height: 120%;
                        margin: 24px 0 16px;
                        text-transform: capitalize;

                        @include max-lg() {
                            font-size: 22px;
                        }
                    }

                    span {
                        color: $text_gray_color;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 140%;
                    }

                    button {
                        padding: 8px 20px;
                        gap: 10px;
                        width: 246px;
                        height: 48px;
                        margin-top: 24px;

                        @include max-lg() {
                            width: 254px;
                        }

                        @include max-xxs() {
                            width: 220px;
                        }

                        span {
                            color: $text_white;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 32px;
                            padding: 12px 6px;

                            @include max-xxs() {
                                font-size: 17px;
                                padding: 12px 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// beam version 
.thank-you-page-BEAM {
    button {
        background: $beam_gradient;
        height: 60px!important;
        width: 260px!important;

        span {
            font-weight: 400!important;
            padding: 14px 0!important;
        }
    }
}