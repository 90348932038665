.content-page {
    background-color: $text_white;
    border-bottom: 1px solid black;

    &__container {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 46px;

        @include max-lg() {
            padding-top: 6px;
        }

        .mobile-header {
            h1 {
                line-height: 128%;
                // font-size: 28px;
            }

            .date {
                font-size: 16px;
                margin-bottom: 14px;
                color: $light_gray;
            }

            h2 {
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
            }

            h3 {
                font-size: 18px;
                line-height: 140%;
                font-weight: 500;
                margin-top: 18px;
            }

            .img {
                max-width: 460px;
                width: 100%;
                margin: auto;
                overflow: auto;
                background-size: cover;
                background-position: top;
                margin-top: 18px;

                @include min-xs() {
                    min-height: 320px;
                }

                @include max-xs() {
                    width: 100%;
                    height: 0;
                    padding-top: 69%;
                }
            }
        }


        .body {
            display: flex;
            width: 100%;
            height: 100%;
            position: relative;

            @include max-lg() {
                flex-direction: column;
            }

            &__main {
                width: 100%;
                color: $text_black;

                &__heading {
                    h1 {
                        margin-bottom: 20px;
                        font-size: 38px;
                        line-height: 128%;
                    }

                    .date {
                        margin-bottom: 20px;
                        color: $light_gray;
                    }

                    h3 {
                        margin-bottom: 25px;
                        line-height: 140%;
                        font-weight: 300;
                        font-size: 22px;
                    }

                    img {
                        width: 100%;
                    }
                }

                &__text {
                    div {
                        margin-top: 36px;
                        line-height: 26px;
                        font-weight: 300;

                        @include max-lg() {
                            margin-top: 26px;
                        }

                        h3 {
                            color: $text_black;
                            line-height: 24px;
                            margin-bottom: 16px;

                            @include max-lg() {
                                line-height: 30px;
                            }
                        }
                    }

                    .points {
                        span {
                            line-height: 24px;
                        }

                        ul {
                            margin-top: 16px;
                            list-style-position: outside;
                            margin-left: 5%;

                            @include max-lg() {
                                margin-left: 9%;
                            }
                        }
                    }

                    .bonus {
                        background: $background_gray_obie;
                        padding: 12px 46px 12px 28px;

                        @include max-lg() {
                            padding: 14px 21px 14px 32px;
                        }

                        &__more {
                            font-size: 17px;
                            margin: 4px 0 0 12px;

                            .second {
                                margin-left: 12px;
                            }
                        }
                    }
                }

                &__about {
                    display: flex;
                    flex-direction: column;
                    margin-top: 36px;
                    font-weight: 300;
                    
                    @include max-lg() {
                        margin-bottom: 18px;
                    }

                    h4 {
                        font-size: 18px;
                        line-height: 133%;
                        margin-bottom: 16px;
                    }

                    .middle {
                        margin: 40px 0 20px;
                    }
                }

                .about-w-resources {
                    border-top: 1px solid black;
                    border-bottom: 1px solid black;
                    padding: 30px 0;

                    .middle {
                        margin: 40px 0 20px;
                    }
                }

                &__resources {
                    color: black;
                    margin-top: 36px;
                    font-size: 13px;
                    font-weight: 300;
                    line-height: 18px;
                    overflow: auto;

                    p {
                        margin-bottom: 10px;
                    }

                    a {
                        color: black;
                    }
                }

                .disclaimer {
                    color: #b6b6b6;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 17px;
                    margin-top: 36px !important;
                }
            }
        }
    }

    // form styling
    .body-form {
        @include min-lg() {
            margin-left: 43px;
            position: -webkit-sticky;
            position: sticky;
            top: 16px;
            align-self: flex-start; 
        }

        @include max-lg() {
            #contact-us {
                padding: 0!important;
            }

            #obieForm {
                width: 100%;
            }
        }

        @include max-xxs() {
            button {
                font-size: 16px;
            }
        }

        // styling for FormContentLPs in: /sections/ContactUs/ContentLPs/
        .contact-us {
            border: double 2px transparent;
            border-radius: 15px;
            background-origin: border-box;
            background-clip: content-box, border-box; 

            @include max-lg() {
                margin-top: 30px;
                scroll-margin: 200px;
            }

            &__container {
                display: flex;
                flex-direction: column;
                margin: 26px 30px 26px;

                @include max-lg() {
                    margin: 22px 16px 22px;

                    div {
                        margin-top: 0;
                    }
                }

                &__heading {
                    text-align: center;
                    margin-bottom: 17px;

                    h2 {
                        font-size: 24px;
                        line-height: 30px;

                        @include max-lg() {
                            font-size: 20px;
                            line-height: 25px;
                        }
                    }

                    h3 {
                        color: $text_color;
                        font-size: 18px;
                        line-height: 23px;
                        font-weight: 500;
                        margin-top: 8px;

                        @include max-lg() {
                            font-size: 17px;
                            line-height: 22px;
                        }
                    }
                }

                .form-info {
                    text-align: center;
                    font-size: 16px;
                    line-height: 19px;
                    color: $light_gray;
                    margin-top: 18px;

                    @include max-xxs() {
                        font-size: 15px;
                    }
                }
            }
        }

        .budget-friendly {
            color: $light_gray;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            margin-top: 18px!important;
        }

        .gdpr-message {
            color: #b6b6b6;
            font-size: 14px;
            font-weight: 300;
            line-height: 17px;
            margin: auto;
            max-width: 380px;
            text-align: center;
            margin-top: 18px!important;

            a {
                color: #b6b6b6;
                text-decoration: underline;
            }
        }
    }
}

// *****
// different version styling
// beam version
.content-page-BEAM {
    border-top: 2px solid #DFDFDF;

    @include max-lg() {
        border-top: 2px solid #DFDFDF;
    }

    .mobile-header {
        h1 {
            font-size: 26px;
            margin: 16px 0 8px;
        }

        h3 {
            color: $text_black;
        }
    }
    
    .body {
        &__main {
            &__heading {
                h3 {
                    color: black;
                }
            }

            &__text {
                div {
                    h3 {
                        font-weight: 500;
                    }
                }

                .points {
                    span {
                        font-weight: 500;
                    }
                }

                .bonus {
                    border-left: 5px solid $beam_blue;
                }
            }

            &__about {
                h4 {
                    color: $beam_blue;
                    font-weight: 500;
                }
            }
        }

        // form styling 
        .body-form {
            .contact-us {
                background-image: linear-gradient(white, white), 
                                  linear-gradient(23.33deg, #D966C0 19.27%, #559EC9 90.59%);
            }
        }
    }
}

// obie version
.content-page-Obie {
    .mobile-header {
        h1 {
            font-size: 26px;
            margin: 16px 0 8px;
        }

        h3 {
            color: $text_gray_color;
            font-weight: 700;
        }
    }

    .body {
        &__main {
            &__heading {
                h3 {
                    color: $text_gray_color;
                }
            }
            
            &__text {
                div {
                    h3 {
                        font-weight: 700;
                    }
                }

                .points {
                    span {
                        font-weight: 700;
                    }
                }

                .bonus {
                    border-left: 5px solid $obie_pink;
                    line-height: 24px;
                }
            }

            &__about {
                h4 {
                    color: $obie_pink;
                    font-weight: 700;
                }
            }
        }

        // form styling 
        .body-form {
            .contact-us {
                background-image: linear-gradient(white, white), 
                                  linear-gradient(94.66deg, #FFD470 0%, #FF58AE 100%);
            }
        }
    }
}

// ucla study
.ucla-sub-ul {
    margin-top: 10px!important;
    margin-bottom: 10px;
}