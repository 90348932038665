// base colors 
$text_color: #181818;
$text_gray_color: #565656;
$text_dark_gray: #585858;
$text_white: #FFFFFF;
$text_red: #E21E1E;
$text_error_red: #D91717;
$text_black: #000000;
$border_black: #000000;
$light_gray: #989898;

// reseller brand colors
$reseller_orange: #FD7E40;
$reseller_green: #299D64;

// obie brand colors
$background_gray_obie: #F5F5F6;
$background_yellow: #FFE4A5;
$obie_pink: #FA737D;
$obie_light_pink: #FF838C;

// beam brand colors
$background_gray_beam: #FCFCFC;
$background_black_beam: #141415;
$beam_pink: #EA5BC4;
$beam_blue: #36A0CD;
$beam_blue_gradient: linear-gradient(123.44deg, #65AFD4 19.74%, #76C1DF 121.68%);
$beam_gradient: linear-gradient(43.28deg, #D966C0 25.75%, #559EC9 74.25%);
$beam_gradient_hover: linear-gradient(43.28deg, #E979D0 25.75%, #6EB6E0 74.25%);
$beam_gradient_sticky: linear-gradient(23.33deg, #D966C0 19.27%, #559EC9 90.59%);