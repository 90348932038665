// display sizes
$screen-xxs: 320px;
$screen-xs: 480px;
$screen-sm: 600px;
$screen-md: 769px;
$screen-lg: 900px;
$screen-ml: 1024px;
$screen-xl: 1200px;

// query xxs
@mixin min-xxs {
    @media (min-width: #{$screen-xxs}) {
      @content;
    }
  }
  @mixin max-xxs {
    @media (max-width: #{$screen-xxs}) {
      @content;
    }
  }

// query xs
@mixin min-xs {
  @media (min-width: #{$screen-xs}) {
    @content;
  }
}
@mixin max-xs {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}

// query sm
@mixin min-sm {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}
@mixin max-sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// query md
@mixin min-md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}
@mixin max-md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

// query lg
@mixin min-lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}
@mixin max-lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

// query ml
@mixin min-ml {
  @media (min-width: #{$screen-ml}) {
    @content;
  }
}
@mixin max-ml {
  @media (max-width: #{$screen-ml}) {
    @content;
  }
}

// query xl
@mixin min-xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}
@mixin max-xl {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}