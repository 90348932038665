#contact-us {
    overflow: hidden;

    @include max-lg() {
        padding: 50px 8px 26px!important;
    }
}


// contact us - 1st version
.contact-us {
    &__header {
        text-align: center;
        margin-bottom: 40px;

        h2 {
            margin-bottom: 10px;
        }
    }

    &__body {
        display: flex;
        flex-direction: row;

        @include max-lg() {
            flex-direction: column;
        }

        .form-container {
            width: 429px;

            @include max-lg() {
                width: 343px;
                margin: auto;
            }

            @media (max-width: 360px) {
                width: 95%;
            }

            &__border {
                // border: 2px solid;
                // border-image: linear-gradient(94.66deg, #FFD470 0%, #FF58AE 100%) 2;
                // border-radius: 15px;
                border: double 2px transparent;
                border-radius: 15px;
                background-image: linear-gradient(white, white), 
                                  linear-gradient(94.66deg, #FFD470 0%, #FF58AE 100%);
                background-origin: border-box;
                background-clip: content-box, border-box;
                margin-bottom: 18px;

                &__main {
                    margin: 21px 57px 33px 30px;

                    @include max-lg() {
                        margin: 25px 20px 83px;
                    }

                    @media (max-width: 360px) {
                        margin: 20px 12px 83px;
                    }

                    .span {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        color: $light_gray;
                        margin-top: 18px;
                    }

                    button {
                        @include max-xxs() {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .img-container {
            display: flex;
            align-items: flex-end;

            @include max-lg() {
                flex-direction: column;
                align-items: center;
            }

            img {
                margin: 0 0 18px -62px;

                @include max-lg() {
                    margin: -80px 0 -10px;
                    max-width: 327px;
                }

                @media (max-width: 360px) {
                    max-width: none;
                    width: 90%;
                }
            }
        }

        .accept-tnc {
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #B6B6B6;
            max-width: 380px;
            margin: auto;

            @include max-lg() {
                max-width: 343px;
            }

            @media (max-width: 360px) {
                max-width: none;
                width: 90%;
            }
        }
    }
}


// contact us - 2nd version
.contact-section {
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 100%;

    @include max-lg() {
        flex-direction: column;
        align-items: center;
    }

    @media (min-width: 2400px) {
        max-width: 1350px;
    }

    .contact-section-text {
        position: relative;
        width: 50%;

        @include max-lg() {
            width: 100%;
            margin-right: 0;
        }

        &__container {
            top: 90px;

            @include min-lg() {
                position: absolute;
                right: -12px;
            }

            .text {
                @include min-lg() {
                    margin-left: 26px;
                }

                &__header {
                    @include max-lg() {
                        display: flex;
                        justify-content: center;

                        .img-container {
                            margin-top: 15px;

                            @include max-xxs() {
                                margin-top: 30px;
                            }

                            img {
                                width: 208px;

                                @media (max-width: 405px) {
                                    width: 190px;
                                }

                                @media (max-width: 380px) {
                                    width: 152px;
                                }

                                @include max-xxs() {
                                    width: 142px;
                                }
                            }
                        }
                    }

                    h1 {
                        font-weight: 400;
                        font-size: 40px;
                        line-height: 126.69%;
                        text-transform: capitalize;
                        max-width: 450px;

                        @include max-xl() {
                            max-width: none;
                        }

                        @include max-lg() {
                            font-weight: 700;
                            font-size: 28px;
                            line-height: 126.69%;
                            max-width: none;
                            text-align: center;
                        }

                        @media (max-width: 380px) {
                            font-size: 26px;
                        }
                    }
                }

                h2 {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 34px;
                    color: $text_gray_color;
                    margin-top: 21px;

                    @include min-xl() {
                        width: 400px;
                    }

                    @include max-lg() {
                        font-size: 18px;
                        line-height: 126.69%;
                        text-align: center;
                        margin-top: 14px;
                    }
                }
            }

            .image {
                margin-top: 56px;

                img {
                    width: 105%;

                    @include max-xl() {
                        width: 100%;
                        max-width: 520px;
                    }
                }
            }
        }
    }

    .contact-section-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        margin-left: 61px;
        max-width: 429px;

        @include max-xl() {
            margin-left: 30px;
        }

        @include max-lg() {
            width: 100%;
            margin-top: 28px;
            margin-left: 0;
        }

        &__container {
            position: relative;
            border: double 2px transparent;
            border-radius: 15px;
            background-image: linear-gradient(white, white), 
                              linear-gradient(94.66deg, #FFD470 0%, #FF58AE 100%);
            background-origin: border-box;
            background-clip: content-box, border-box;

            .form {
                margin: 21px 57px 33px 30px;

                @include max-lg() {
                    margin: 27px 21px 83px 22px;
                }

                @include max-xxs() {
                    margin: 22px 16px 52px 17px;

                    button {
                        font-size: 19px;
                        height: 54px;
                    }
                }

                .span {
                    color: $light_gray;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    margin-top: 16px;
                    text-align: center;

                    @include min-lg() {
                        width: 275px;
                        margin: 16px auto 0;
                    }

                    @include max-lg() {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
        }

        .under-form-img {
            position: absolute;
            max-width: 327px;
            bottom: -196px;
            left: 4%;

                img {
                    width: 100%;
                }

            @media (max-width: 400px) {
                bottom: -49vw;
            }
        }

        #obieForm {
            @include max-xl() {
                width: 105%;
            }

            @include max-lg() {
                width: 100%;
            }
        }

        .tnc {
            margin-top: 18px;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #B6B6B6;

            @include max-lg() {
                margin-top: 176px;
            }

            @media (max-width: 400px) {
                margin-top: 46vw;
            }

            a {
                color: #B6B6B6;
                text-decoration: underline;
            }
        }
    }
}


// contact us - 3rd version
.obie-games-version {

    .contact-section-text {
        &__container {
            top: 30px;

            @include min-xl() {
                right: 80px!important;
            }

            @media (max-width: $screen-xl) and (min-width: $screen-lg) {
                right: 0!important;
                
                .text {
                    margin-left: 0!important;
                }
            }

            @media (max-width: $screen-xl) and (min-width: $screen-ml) {
                left: 50px!important;
            }
            @media (max-width: $screen-ml) and (min-width: $screen-lg) {
                left: 20px!important;
            }

            .text {
                &__header {
                    h1 {
                        font-size: 46px;

                        @include max-lg() {
                            font-size: 24px;
                        }
                    }
                }

                h2 {
                    margin-top: 20px;

                    @include max-lg() {
                        margin-top: 12px;
                    }
                }
            }
        }
    }

    .image {
        img {
            position: absolute;
            width: 1000px!important;
            left: -66%;

            @include max-xl() {
                width: 86vw!important;
                max-width: none!important;
            }

            @include max-ml() {
                left: -26vw;
            }
        }
    }

    .form {
        margin: 21px 33px 33px!important;

        @include max-lg() {
            margin: 25px 21px 82px 22px!important;
        }

        @include max-xxs() {
            margin: 22px 16px 75px 17px!important;
        }

        #obieForm {
            width: 100%!important;
        }
    }

    .under-form-img {
        display: flex;
        justify-content: center;
        bottom: -200px!important;

            img {
                width: 180%!important;
            }

        @media (max-width: 400px) {
            bottom: -46vw!important;
        }
    }

    .tnc {
        @include max-lg() {
            margin-top: 190px!important;
        }

        @media (max-width: 400px) {
            margin-top: 44vw!important;
        }
    }
}