$page-width: 100%;
$color-white: #fff;
$color-black: #000;
$margin-1: 1rem;
$margin-2: 2rem;
$page-margin-left: 1rem;
$page-margin-right: 1rem;
$section-color: #FFEFC7;

.reseller-page * {
    font-family: 'Jost';
    line-height: 120%;
    overflow: hidden;

}
.reseller-page {

    form {
        max-width: 100% !important;
        background: #fff;
        padding: 2rem 0.5rem;
        border-radius: 1rem;
    }

    @media screen and (min-width: 1024px) {
        form {
            padding: 4rem 2rem;
            width: 750px !important;
        }
    }

    input {
        border-radius: 30px !important;
        padding: 1rem 1.5rem !important;
        width: 100% !important; 
    }

    .form-control {
        border-radius: 20px;
    }

    h1, h2, h3, h4, h5, h6, p, span, i {
        overflow: hidden;
    }

    svg {
        fill: #FD7E40
    }
    &__section-1 {

        &_container {
            background-color: $section-color;
            width: 100%;
        }
        &_headline {

            margin-top: $margin-2;
            text-align: center;
            font-size: 36;
        }
    }

    .backgroundwhite {
        background-color: white;
    }

    
}