.hero-image-lp {
    width: 100%;
    
    @include min-lg() {
        height: 563px;
    }

    // ********
    // themes
    .theme-Obie {
        .hero-text {
            @include min-lg() {
                padding-bottom: 20px;
            }
            
            @include max-lg() {
                margin: 15px 0 28px;
            }

            h1 {
                font-size: 48px;
                line-height: 61px;

                @include max-lg() {
                    font-size: 29px;
                    line-height: 122%;
                }
            }

            h2 {
                font-size: 22px;
                line-height: 130%;
                margin: 16px 0 30px;
                font-weight: 400;

                @include max-xl() {
                    font-size: 20px;
                    line-height: 140%;
                    margin: 12px 0 24px;
                }

                @include max-lg() {
                    font-size: 22px;
                    line-height: 125%;
                    margin: 12px 0 20px;
                }
            }
        }
    }

    .theme-BEAM {
        .hero-text {
            @include min-xl() {
                padding-bottom: 30px;
            }

            @include max-lg() {
                margin: 19px 0 25px;
            }

            h1 {
                font-size: 40px;
                line-height: 129%;

                @include max-ml() {
                    font-size: 38px;
                }

                @include max-lg() {
                    font-size: 26px;
                    line-height: 133%;
                }
            }

            h2 {
                line-height: 136%;
                margin: 21px 0 32px;

                @include max-ml() {
                    font-size: 22px;
                }

                @include max-lg() {
                    font-size: 20px;
                    margin: 13px 0 24px;
                }
            }
        }
    }


    // ********
    // section styling
    &__container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;

        @include min-lg() {
            height: 563px;
        }

        @include max-lg() {
            flex-direction: column;        
        }

        &__base {
            width: 100%;
            height: 563px;
            display: flex;
            flex-direction: row;

            .no-hero-img {
                width: 50%;
                height: 100%;
            }

            .hero-img {
                width: 50%;
                height: 100%;
                background-size: cover;
                background-position: left;

                @media (min-width: 1890px) {
                    background-position: top;
                }
            }
        }

        &__info {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            position: absolute;
            top: 0;
            margin: auto;

            @include max-lg() {
                position: relative;
            }

            .hero-text {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;

                @include min-lg() {
                    justify-content: center;
                    padding-right: 40px;
                }

                @include max-lg() {
                    width: 100%;
                }

                h2 {
                    font-size: 24px;
                    font-weight: 400;
                    color: $text_gray_color;

                    @include max-lg() {
                        font-weight: 300;
                    }
                }

                .get-btn {
                    button {
                        width: 323px;

                        @include max-lg() {
                            width: 100%;
                        }

                        span {
                            font-weight: 400;
                            padding: 14px 10px;
                        }
                    }
                }

                .budget-options {
                    color: $light_gray;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    width: 323px;
                    margin-top: 18px;
                }
            }

            .hero-no-text {
                width: 50%;
                height: 100%;
            }
        }

        &__mobile {
            min-height: 440px;
            width: 100%;
            max-width: 435px;
            margin: auto;
            background-size: cover;
            background-position: top;
        }
    }


    // ********
    // more versions
    .type-education, .type-churches, .type-hotels, .type-interactive-floor-projector {
        .hero-img {
            background-position: center;
        }
    }

    // versions by themes
    .theme-Obie {

        .ml-hide {
            @include max-ml() {
                display: none;
            }
        }

        .info-type-medical {
            .hero-text {
                h2 {
                    font-size: 24px;
                    line-height: 125%;
    
                    @include max-lg() {
                        font-size: 22px;
                        margin: 14px 0 21px;
                        font-weight: 500;
                    }
                }
            }
        }

        .info-type-education {
            .hero-text {
                h1 {
                    font-size: 46px;
                    line-height: 124%;

                    @include max-xl() {
                        font-size: 44px;
                    }
    
                    @include max-lg() {
                        font-size: 29px;
                        line-height: 122%;
                    }
                }
            }
        }

        .info-type-fec {
            .hero-text {
                h1 {
                    font-size: 43px;
                    line-height: 124%;

                    @include max-xl() {
                        font-size: 39px;
                    }
    
                    @include max-lg() {
                        font-size: 29px;
                        line-height: 122%;
                    }
                }
            }
        }

        .info-type-seniors {
            .hero-text {
                h1 {
                    font-size: 46px;
                    line-height: 126%;

                    @include max-xl() {
                        font-size: 43px;
                    }
    
                    @include max-lg() {
                        font-size: 29px;
                        line-height: 122%;
                    }
                }
            }
        }

        .info-type-education, .info-type-fec {
            .hero-text {
                @media (max-width: $screen-ml) and (min-width: $screen-lg) {
                    .budget-options {
                        font-size: 15px;
                        margin-top: 14px;
                    }
                }
            }
        }
    }

    // background image position different for seniors
    .type-seniors {
        .hero-img {
            @media (min-width: 1890px) {
                background-position: center;
            }
        }
    }
}