// hero image lp
.heroImageLP {
    background-color: $text_white;
    z-index: 15;

    .hero {
        width: 100%;
        height: 563px;
        display: flex;

        @include max-lg() {
            height: 100%;
            margin: auto;
            display: block
        }
    }

    .hero-text {
        display: flex;
        flex-direction: column;
        width: 50%;
        position: relative;
        padding: 51px 140px 0 0;
        align-items: flex-end;
        background: $text_white;

        @media (max-width: 1850px) {
            padding: 51px 98px 0 0;
        }

        @include max-xl() {
            padding: 51px 15px 0 50px;
            align-items: flex-start;
        }

        @media (max-width: 1025px) {
            padding: 30px 15px 0 50px;
        }

        @include max-lg() {
            max-width: 350px;
            width: 100%;
            padding: 0;
            margin: auto;
        }

        @media (max-width: 400px) {
            max-width: 88%;
        }

        .hero-text-container {
            max-width: 450px;
        }

        h1 {
            margin: 0;

            @include max-lg() {
                margin-top: 9px;
            }
        }
        
        h2 {
            font-size: 24px;
            font-weight: 400;
            line-height: 30px;
            color: $text_gray_color;
            margin: 11px 0 30px;

            @include max-lg() {
                font-size: 22px;
                line-height: 28px;
                margin: 10px 0 19px;
            }
        }

        .hero-text-btn {
            display: flex;
            flex-direction: column;
            width: 100%;

            button {
                @include min-lg() {
                    width: 323px;
                }

                @include max-lg() {
                    width: 100%;
                }
            }

            .desktop-span {
                font-size: 16px;
                color: $light_gray;
                width: 320px;
                text-align: center;
                margin-top: 18px;
            }
        }
    }

    .hero-img {
        background-image: url('https://obie-files.s3.eu-west-1.amazonaws.com/playobie-lps/hero/hero-image-lp.png');
        width: 50%;
        background-size: cover;
        background-position: initial;
        position: relative;

        @media (min-width: 2000px) {
            background-position: center;
        }

        @include max-lg() {
            background-image: url('https://obie-files.s3.eu-west-1.amazonaws.com/playobie-lps/hero/hero-image-lp-mobile.jpg');
            background-position: center;
            width: 100%;
            max-width: 480px;
            // min-height: 376px;
            min-height: 430px;
            height: 100%;
            margin: 28px auto 0;
        }

        @include max-xs() {
            // min-height: 420px;
            min-height: 376px;
        }

        img {
            position: absolute;
            width: 56%;
            top: 0;
            right: 32px;

            @include max-xxs() {
                width: 62%;
            }
        }
    }
}

// hero image lp - medical version
.medical {
    @media (min-width: 1100px) {
        padding: 24px 98px 0 50px!important;
    }

    @media (max-width: 1100px) and (min-width: 900px) {
        padding: 24px 24px 0 41px!important;
    }

    .hero-text-container {
        h1 {
            @media (max-width: 975px) {
                font-size: 44px;
            }

            @include max-lg() {
                font-size: 29px;
                line-height: 122%;
            }
        }
    }
}

// hero image lp - education version
.education {
    @media (min-width: 1100px) {
        padding: 24px 34px 0 50px!important;
    }

    @media (max-width: 1100px) and (min-width: 1020px) {
        padding: 18px 32px 0 32px!important;
    }

    @media (max-width: 1020px) and (min-width: 900px) {
        padding: 18px 16px 0 32px!important;
    }

    .hero-text-container {
        max-width: 512px!important;

        h1 {
            font-size: 46px;
            line-height: 126%;

            @media (max-width: 1000px) {
                font-size: 42px;
            }

            @include max-lg() {
                font-size: 29px;
                line-height: 122%;
            }
        }

        h2 {
            font-size: 22px;
            line-height: 29px;
            margin: 20px 0 30px;

            @media (max-width: 1020px) {
                font-size: 20px;
            }

            @include max-lg() {
                font-size: 22px;
                line-height: 125%;
                margin: 12px 0 20px;
            }
        }
    }
}

// hero image lp - fec version
.fec {
    @include min-xl() {
        padding: 40px 30px 0 50px!important;
    }

    @media (max-width: $screen-xl) and (min-width: 1100px) {
        padding: 18px 24px 0 54px!important;
    }

    @media (max-width: 1100px) and (min-width: 1020px) {
        padding: 18px 32px 0 50px!important;
    }

    @media (max-width: 1020px) and (min-width: 900px) {
        padding: 18px 16px 0 46px!important;
    }

    .hero-text-container {
        max-width: 520px!important;

        h1 {
            font-size: 44px;
            line-height: 124%;

            @media (max-width: 1020px) {
                font-size: 40px;
            }

            @include max-lg() {
                font-size: 29px;
                line-height: 122%;
            }
        }

        h2 {
            font-size: 22px;
            line-height: 29px;
            margin: 20px 0 30px;

            @media (max-width: 1020px) {
                font-size: 20px;
            }

            @include max-lg() {
                font-size: 22px;
                line-height: 125%;
                margin: 12px 0 20px;
            }
        }
    }
}

// hero image lp - medical version
.floor {
    @include min-xl() {
        padding: 40px 30px 0 50px!important;
    }

    @media (max-width: $screen-xl) and (min-width: 1100px) {
        padding: 18px 24px 0 54px!important;
    }

    @media (max-width: 1100px) and (min-width: 1020px) {
        padding: 18px 32px 0 50px!important;
    }

    @media (max-width: 1020px) and (min-width: 900px) {
        padding: 18px 16px 0 46px!important;
    }

    .hero-text-container {
        max-width: 520px!important;

        h1 {
            font-size: 40px;
            line-height: 124%;

            @media (max-width: 1020px) {
                font-size: 40px;
            }

            @include max-lg() {
                font-size: 29px;
                line-height: 122%;
            }
        }

        h2 {
            font-size: 22px;
            line-height: 29px;
            margin: 20px 0 30px;

            @media (max-width: 1020px) {
                font-size: 20px;
            }

            @include max-lg() {
                font-size: 22px;
                line-height: 125%;
                margin: 12px 0 20px;
            }
        }
    }
}



// ********
// hero lead form lp
.leadFormLP {
    background-color: $text_white;

    .hero {
        margin: auto;
        display: flex;
        flex-direction: row;
        padding: 50px 0;

        @include max-lg() {
            flex-direction: column;
            align-items: center;
            padding: 22px 0 30px;
        }

        @media (min-width: 2400px) {
            max-width: 1350px;
        }

        .hero-text {
            position: relative;
            width: 50%;

            @include max-lg() {
                width: 100%;
                margin-right: 0;
            }

            &__container {
                @include min-lg() {
                    position: absolute;
                    right: -12px;
                    top: 21px;
                }

                .text {
                    @include min-lg() {
                        margin-left: 26px;
                    }

                    &__header {
                        @include max-lg() {
                            display: flex;

                            .img-container {
                                margin-top: 15px;

                                @include max-xxs() {
                                    margin-top: 30px;
                                }

                                img {
                                    width: 208px;

                                    @media (max-width: 405px) {
                                        width: 190px;
                                    }

                                    @media (max-width: 380px) {
                                        width: 152px;
                                    }

                                    @include max-xxs() {
                                        width: 142px;
                                    }
                                }
                            }
                        }

                        h1 {
                            font-weight: 400;
                            font-size: 46px;
                            line-height: 126.69%;
                            text-transform: capitalize;
                            max-width: 450px;

                            @include max-xl() {
                                max-width: none;
                            }

                            @include max-lg() {
                                font-weight: 700;
                                font-size: 28px;
                                line-height: 122%;
                                max-width: none;
                            }

                            @media (max-width: 380px) {
                                font-size: 26px;
                            }
                        }
                    }

                    h2 {
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 34px;
                        color: $text_gray_color;
                        margin-top: 21px;

                        @include min-xl() {
                            width: 400px;
                        }

                        @include max-lg() {
                            font-size: 20px;
                            line-height: 126.69%;
                            text-align: center;
                        }
                    }
                }

                .image {
                    margin-top: 56px;

                    img {
                        width: 105%;

                        @include max-xl() {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .hero-form {
            display: flex;
            justify-content: center;
            width: 50%;
            margin-left: 25px;

            @include max-lg() {
                width: 100%;
                margin-top: 30px;
                margin-left: 0;
            }

            &__container {
                border: double 2px transparent;
                border-radius: 15px;
                background-image: linear-gradient(white, white), 
                                  linear-gradient(94.66deg, #FFD470 0%, #FF58AE 100%);
                background-origin: border-box;
                background-clip: content-box, border-box;

                .form {
                    margin: 21px 57px 33px 30px;

                    @include max-lg() {
                        margin: 27px 21px 23px 22px;
                    }

                    @include max-xxs() {
                        margin: 22px 16px 19px 17px;

                        button {
                            font-size: 19px;
                            height: 54px;
                        }
                    }

                    .span {
                        color: $light_gray;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        margin-top: 16px;
                        text-align: center;

                        @include min-lg() {
                            width: 275px;
                            margin: 16px auto 0;
                        }

                        @include max-lg() {
                            font-size: 16px;
                            line-height: 19px;
                        }
                    }
                }
            }
        }
    }
}

// seniors version
.hero-img-seniors {
    background-position: center!important;
}