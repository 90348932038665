.sticky-cta-visible {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    
    visibility:visible;
	opacity:1;
    background: $obie_pink;
    top: 0;
    z-index: 200;
    transition:0.5s;

    @include min-lg() {
        display: none!important;
    }
}

.sticky-cta {
    display: block;
    height: 0!important;
    visibility: hidden;
    opacity: 0;
    transition:0.5s;

    @include min-lg() {
        display: none!important;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: $text_white;

        a {
            width: 100%;
            margin: 22px 0 16px;
        }

        button {
            width: 100%;
            background: $text_white;
            color: $text_color;
        }

        button:hover {
            background: #ffffff;
        }

        .under-btn {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            max-width: 270px;
        }
    }
}

// styling for obie version
.sticky-Obie {
    background: $obie_pink;
    height: 147px;

    .sticky-cta__container {
        button {
            font-weight: 600;
        }
    }
}

// styling for seniors version
.sticky-seniors {
    background: $obie_pink;
    height: 108px;

    .sticky-cta__container {
        button {
            font-weight: 600;
        }
    }
}

// styling for beam version
.sticky-BEAM {
    background: $beam_gradient_sticky;
    height: 108px;

    .sticky-cta__container {
        button {
            font-weight: 500;
        }
    }

    .under-btn { line-height: 20px; }
}