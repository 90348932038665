.cookies-message {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 20;

    @include max-lg() {
        bottom: 151px;
    }

    &__container {
        background: #FFFFFF;
        border: 1px solid $obie_pink;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        color: #414148;
        padding: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 808px;
        width: 100%;
        margin: auto;

        .cookie-img {
            margin-right: 10px;
        }

        .text-container {
            @include max-lg() {
                display: flex;
                flex-direction: column;

                .exit-span {
                    margin-top: 5px;
                }
            }

            .exit-span {
                @include min-lg() {
                    margin-left: 4px;
                }
    
                .exit-a {
                    color: #414148;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .accept {
                    color: $obie_pink;
                }
            }
        }

        .exit-img {
            margin-left: 12px;
            cursor: pointer;
        }
    }
}

.cookies-message-BEAM {
    .cookies-message__container {
        border: 1px solid $beam_pink;

        .accept {
            color: $beam_pink!important;
        }
    }
}

.cookies-message-Reseller {
    .cookies-message__container {
        border: 1px solid $reseller_orange;

        .accept {
            color: $reseller_orange!important;
        }
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}