#obieForm {
    width: 342px;
    display: flex;
    flex-direction: column;

    @include max-lg() {
        width: 300px;
    }

    @media (max-width: 360px) {
        width: 100%;
    }

    .form-group {
        margin-bottom: 17px;
    }

    label {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        color: $text_gray_color;
        display: inline-block;
        margin-bottom: 3px;
    }

    input {
        background: #FDFDFD;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 15px;
        width: 100%;
    }

    textarea:focus, input:focus {
        border-radius: 5px;
        outline: none;
    }

    // select styling
    select, option {
        padding: 15px;
        width: 100%;
        font-size: 16px;
        color: #a3a3b2;
        z-index: 2;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        background: #FDFDFD;
    }

    .select-wrapper {
        position: relative;

        img {
            position: absolute;
            top: 44%;
            right: 20px;
            z-index: 5;
            pointer-events: none;
        }
    }

    select:focus, option:focus {
        outline: none;
        border: none;
    }

    // hide arrows in phone input
    input[type='number'] {
        -moz-appearance:textfield;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    // label pink when input focues
    .is-invalid-div:focus-within label {
        color: $text_red!important;
    }

    .is-invalid-div {
        textarea:focus, input:focus {
            border-color: $text_red!important;
        }
    }

    // invalid styling
    .label-invalid {
        color: $text_red;
    }

    .is-invalid {
        border: 1px solid $text_red;
    }

    .is-invalid input:focus {
        border: 1px solid $text_red!important;
    }

    // placeholders
    ::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #a3a3b2;
    }

    .invalid-feedback {
        color: $text_error_red;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
    }

    .invalid-feedback-valid-form {
        color: $text_error_red;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        margin-top: 10px;
        text-align: center;
    }
}   

// obie and beam versions 
.obie-form {
    textarea:focus, input:focus {
        border: 1px solid $obie_pink!important;
    }

    select:focus, option:focus {
        box-shadow: 0 0 1px 1px $obie_pink!important;
    }

    .form-group:focus-within label {
        color: $obie_pink!important;
    }
}

.beam-form {
    textarea:focus, input:focus {
        border: 1px solid $beam_pink!important;
    }

    select:focus, option:focus {
        box-shadow: 0 0 1px 1px $beam_pink!important;
    }

    .form-group:focus-within label {
        color: $beam_pink!important;
    }

    button {
        font-weight: 400;
    }
}