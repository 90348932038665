// base
@import './styles/mixin.scss';
@import './styles/themes.scss';
@import './styles/variables.scss';
@import './styles/header.scss';
@import './styles/footer.scss';

// components 
@import './styles/components/carousel/carousel.scss';
@import './styles/components/form/form.scss';
@import './styles/components/cookiesMessage/cookiesMessage.scss';
@import './styles/components/thankYouPopup/thankYouPopup.scss';

// pages
@import './styles/pages/thankYou.scss';
@import './styles/pages/content/index.scss';

// reseller
@import './styles/pages/reseller/resellerFooter.scss';
@import './styles/pages/reseller/resellerHeader.scss';
@import './styles/pages/reseller/resellerNoPage.scss';
@import './styles/pages/reseller/resellerPage.scss';
@import './styles/pages/reseller/resellerThankYou.scss';

// sections
@import './styles/sections/hero/hero.scss';
@import './styles/sections/hero/imageLP/index.scss';
@import './styles/sections/clientsStrip/clientsStrip.scss';
@import './styles/sections/meetObie/meetObie.scss';
@import './styles/sections/meet/beam/index.scss';
@import './styles/sections/awards/awards.scss';
@import './styles/sections/testimonials/testimonials.scss';
@import './styles/sections/contactUs/contactUs.scss';
@import './styles/sections/contactUs/LeadGenLPs/index.scss';
@import './styles/sections/stickyCTA/stickyCTA.scss';
@import './styles/sections/img/index.scss';


// **************
// fonts

// Brown Pro
@font-face {
  font-family: 'Brown Pro';
  src: url('../public/fonts/BrownPro/lineto-brown-pro-regular.eot')
      format('embedded-opentype'),
    url('../public/fonts/BrownPro/lineto-brown-pro-regular.woff') format('woff'),
    url('../public/fonts/BrownPro/lineto-brown-pro-regular.woff2') format('woff2'),
    url('../public/fonts/BrownPro/lineto-brown-pro-light.eot')
      format('embedded-opentype'),
    url('../public/fonts/BrownPro/lineto-brown-pro-light.woff') format('woff'),
    url('../public/fonts/BrownPro/lineto-brown-pro-light.woff2') format('woff2'),
    url('../public/fonts/BrownPro/lineto-brown-pro-bold.eot') format('embedded-opentype'),
    url('../public/fonts/BrownPro/lineto-brown-pro-bold.woff') format('woff'),
    url('../public/fonts/BrownPro/lineto-brown-pro-bold.woff2') format('woff2');
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: 'Brown Pro';
  src: url('../public/fonts/BrownPro/lineto-brown-pro-light.eot')
      format('embedded-opentype'),
    url('../public/fonts/BrownPro/lineto-brown-pro-light.woff') format('woff'),
    url('../public/fonts/BrownPro/lineto-brown-pro-light.woff2') format('woff2');
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: 'Brown Pro';
  src: url('../public/fonts/BrownPro/lineto-brown-pro-bold.eot')
      format('embedded-opentype'),
    url('../public/fonts/BrownPro/lineto-brown-pro-bold.woff') format('woff'),
    url('../public/fonts/BrownPro/lineto-brown-pro-bold.woff2') format('woff2');
  font-display: swap;
  font-weight: 700;
}
// Gotham
@font-face {
  font-family: 'Gotham';
  src: url('../public/fonts/Gotham/gotham_book-webfont.woff2') format('woff2'),
       url('../public/fonts/Gotham/gotham_book-webfont.woff') format('woff');
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: 'Gotham';
  src: url('../public/fonts/Gotham/gotham-medium-webfont.woff2') format('woff2'),
       url('../public/fonts/Gotham/gotham-mediumitalic-webfont.woff') format('woff');
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: 'Gotham';
  src: url('../public/fonts/Gotham/gotham-bold-webfont.woff2') format('woff2'),
       url('../public/fonts/Gotham/gotham-bold-webfont.woff') format('woff');
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: 'Gotham';
  src: url('../public/fonts/Gotham/gotham-light-webfont.woff2') format('woff2'),
       url('../public/fonts/Gotham/gotham-light-webfont.woff') format('woff');
  font-display: swap;
  font-weight: 300;
}

// **************
// global styles
*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;
  color: $text_color;
  font-size: 18px;
  line-height: 22px;
  position: relative;

  -webkit-animation: gradient 16s ease infinite;
  -moz-animation: gradient 16s ease infinite;
  animation: gradient 16s ease infinite;
}

// html, body, #app, #app>div {
//   height: 100%
// }

section {
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $text_white;

  @include max-lg() {
    padding: 50px 8px;
  }
}

// **************
// headers
h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;

  @include max-lg() {
    font-size: 36px;
    line-height: 39px;
  }
}

h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;

  @include max-lg() {
    font-size: 24px;
    line-height: 30px;
  }
}

h3 {
  color: $text_gray_color;
  font-weight: 400;
  font-size: 24px;
  line-height: 125%;
}

// **************
// text and buttons
button {
  color: $text_white;
  font-weight: 500;
  line-height: 32px;
  border-radius: 200px;
  text-decoration: none;
  border: none;
  height: 56px;

  &:hover {
    cursor: pointer;
  }

  span {
    padding: 12px 20px;
  }
}

// **************
// global classes with multiple uses 
.main-width {
  max-width: 60%;
  margin: auto;

  @include min-xl() {
    min-width: 1096px;
    max-width: 1000px!important;
  }

  @include max-xl() {
    max-width: 90%;
  }

  @include max-lg() {
    max-width: 350px;
  }

  @media (max-width: 400px) {
    max-width: 88%;
  }
}

.bold {
  font-weight: 700;
}

.small-span {
  font-size: 16px;
  line-height: 20px;
  color: $text_gray_color;
}

.obie-pink {
  color: $obie_pink;
}

// **************
// hide by display size
.lg-hide {
  @include max-lg() {
    display: none!important;
  }
}

.lg-show {
  @include min-lg() {
    display: none!important;
  }
}

.br-mobile {
  display: none;

  @include max-lg() {
    display: inherit;
  }
}