.contact-us-lead-gen-image-lp {
    width: 100%;
    margin-bottom: -5px;

    &__container {
        display: flex;
        flex-direction: row;
        width: 100%;

        @include max-lg() {
            flex-direction: column;
        }

        .info {
            width: 50%;

            @include max-lg() {
                width: 100%;
            }

            .texts {
                margin-left: 70px;

                @include max-xl() {
                    margin: 0 20px 0 0;
                }

                @include max-lg() {
                    margin: 0;
                    text-align: center;
                }

                h1 {
                    font-size: 40px;
                    line-height: 53px;

                    @include max-lg() {
                        font-size: 24px;
                        line-height: 30px;
                    }
                }
    
                h2 {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 39px;
                    margin-top: 21px;

                    @include max-lg() {
                        font-size: 18px;
                        line-height: 23px;
                        margin-top: 12px;
                        margin-bottom: 28px;
                    }
                }

                .mobile-h1 {
                    margin-bottom: 28px;
                }
            }

            .img {
                margin-bottom: -5px;

                img {
                    margin: 67px 0 -65px -80px;
    
                    @include max-xl() {
                        margin: 67px 0 -65px -94px;
                    }
                }
            }
        }

        .form {
            width: 50%;
            margin-left: 70px;

            @include max-xl() {
                margin-left: 20px;
            }

            @include max-lg() {
                width: 100%;
                margin-left: 0;
            }

            &__container {
                border: double 2px transparent;
                border-radius: 15px;
                background-image: linear-gradient(white, white), 
                                linear-gradient(23.33deg, #D966C0 19.27%, #559EC9 90.59%);
                background-origin: border-box;
                background-clip: content-box, border-box; 
                max-width: 408px;

                .inside-border {
                    margin: 22px 33px 33px 33px;

                    @include max-lg() {
                        margin: 21px 22px 23px 22px;
                    }

                    #obieForm {
                        @include max-lg() {
                            width: 100%;
                        }
                    }

                    p {
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        color: #B6B6B6;
                        margin-top: 18px;

                        a {
                            color: #B6B6B6;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .mobile-img {
        max-width: 472px;
        display: flex;
        justify-content: center;
        margin: 30px 0 -22px;

        img {
            width: 100%;

            @include max-xs() {
                width: 120%;
            }
        }
    }
}

// more versions
.contact-us-lead-gen-image-lp-fec {
    .info {
        margin-top: 30px;
    }
}