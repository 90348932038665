// shared styling for both versions
.header {
    position: sticky;
    z-index: 100;
    top: 0;
    margin-bottom: -22px;

    &__container {
        display: flex;
        margin: auto;
        padding: 13px 0;
        justify-content: space-between;
    }

    &__logo {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        z-index: 100;

        .logo-span {
            @media (max-width: 350px) {
                line-height: 14px;
                margin-top: 5px;
            }
        }
    }

    &__cta {
        display: flex;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        align-items: center;

        button {
            margin-left: 14px;
            height: 44px;
            width: 166px;

            @media (max-width: 400px) {
                margin: 0;
            }

            a {
                color: $text_white;
            }
        }
    }
}

// obie version
.header-Obie {
    background-color: $background_gray_obie;

    .header__container { padding: 13px 0; }
    .header__logo { img { width: 50px; } }
    .header__cta { button { font-size: 20px; } }
}

// seniors version
.header-Seniors {
    background-color: $background_gray_obie;

    .header__container { padding: 13px 0; }
    .header__logo { img { width: 50px; } }
    .header__cta { button { font-size: 18px; } }
}

// beam version
.header-BEAM {
    background-color: $background_gray_beam;

    .header__container { padding: 17px 0; }
    .header__logo { img { width: 54px; } }

    .header__cta {
        font-weight: 300;

        button { font-size: 18px; font-weight: 400; }
    }
}

// make header not sticky on lead gen pages bc we have sticky cta for those
.header-lead-mobile {
    @include max-lg() {
        position: relative;
    }
}

.header-content-desktop {
    @include min-lg() {
        position: relative;
    }
}

// make always sticky
.header-lead-sticky {
    position: sticky;
}