.clients-strip {
    background: $background_gray_obie;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__container {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        margin: 26px 0 12px;
        max-width: 60%;

        @include min-xl() {
          min-width: 1050px;
        }
    
        @include max-xl() {
          min-width: 90%;
        }

        @include max-ml() {
            min-width: 95%;
        }

        @include max-lg() {
            max-width: 100%;
            width: 100%;
        }

        .marquee {

            &__container {
                overflow: hidden;

                &__block {

                    @include max-lg() {
                        --total-marquee-items:5;
                        height: 50px;
                        width: calc(250px * (var(--total-marquee-items)));
                        overflow: hidden;
                        box-sizing: border-box;
                        position: relative;
                        margin: 15px auto;
                    }
                }

                .marquee-inner{
                    @include min-lg() {
                        width: 100%;
                    }

                    @include max-lg() {
                        display: block;
                        width: 200%;
                        position: absolute;
                        animation: marqueeScroll 40s linear infinite;
                    }
                }

                .marquee-inner span{

                    @include min-lg() {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin: 15px 0;
                    }

                    @include max-lg() {
                        float:left;
                        width:50%;
                    }
                }
            }

            .marquee-item {

                @include max-lg() {
                    margin: 0 20px;
                    float: left;
                    transition: all .2s ease-out;
                }

                img {
                    display: block;

                    @include min-lg() {
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
    }
}

// styling for beam version
.clients-strip-BEAM {
    background: $background_gray_beam;
    font-weight: 300;

    .marquee {
        margin-top: 6px;
    }
}

// styling for content pages obie version
.clients-strip-content-page-obie {
    background: $text_white;
    border-bottom: 1px solid black;
}

@keyframes marqueeScroll{
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
}