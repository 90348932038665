.awards {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__header {
        text-align: center;

        h2 {
            margin: 10px 0; 
        }

        @include max-lg() {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                height: 165px;
            }

            &__container {
                width: 215px;
                margin: 0 -25px;
            }
        }

        @include max-xs() {
            width: 100%;

            img {
                max-height: 130px;
                height: auto;
            }

            &__container {
                width: 100%;
                margin: 0 6px;
            }
        }

        @include max-xxs() {
            img {
                max-height: 115px;
            }
        }
    }

    &__body {
        display: flex;
        justify-content: center;
        margin: 50px auto;
        width: 100%;

        @include max-lg() {
            margin: 12px auto;
            padding: 0 10px;
        }

        &__container {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;

            .row {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-around;
                align-items: center;

                @include max-lg() {
                    flex-wrap: wrap;
                    justify-content: space-around;
                }

                .awards-container {
                    display: flex;
                    margin: 10px;

                    @include max-xl() {
                        margin: 10px 14px;
                    }

                    @include max-lg() {
                        width: 35%;
                        margin: 22px 0;
                    }

                    @include max-sm() {
                        margin: 22px auto;
                    }
                }

                img {
                    @include max-xl() {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__body-seniors {
        display: flex;
        flex-direction: row;
        margin: 40px auto 57px;
        justify-content: space-between;
        width: 100%;

        @include max-lg() {
            margin: 34px auto 38px;
        }

        &__container {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-around;

            @include max-xl() {
                align-items: center;
            }

            @include max-lg() {
                flex-direction: column;

                .top-row {
                    margin-bottom: 35px;
                }
            }

            .awards-row {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                width: 100%;
                justify-content: space-around;

                .single-award {
                    display: flex;
                    width: 100%;
                    margin: 0 11px 0;

                    img {
                        width: 107%;
                    }
                }
            }
        }
    }

    &__cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        a {
            width: 100%;
            display: flex;
        }

        button {
            width: 323px;
            margin: auto;
            align-items: center;

            @include max-lg() {
                width: 100%;
            }
        }

        .btn-span {
            @include max-xxs() {
                font-size: 20px;
                padding: 12px 6px;
            }
        }

        .cta-span {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: #989898;
            margin: 18px auto 0;
            text-align: center;
        }
    }

    &__cta-beam {
        .cta-span {
            font-weight: 300;
        }
    }
}